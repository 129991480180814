import { sendToDataLayer } from '@utils';
const COMPLAINT_POLICY_EVENT_CATEGORY = 'content_complaint_policy_section';
export enum ComplainContactType {
  CallCenter = 'callcenter',
  Line = 'line',
  Email = 'email',
  DBD = 'dbd_hyperlink'
}
export const trackComplaintPolicyGoTo = () =>
  sendToDataLayer(undefined, {
    event_category: COMPLAINT_POLICY_EVENT_CATEGORY,
    event_action: 'go_to',
    event_label: `homepage`
  });

export const trackComplaintPolicy = (contactType: ComplainContactType) =>
  sendToDataLayer(undefined, {
    event_category: COMPLAINT_POLICY_EVENT_CATEGORY,
    event_action: 'complaint',
    event_label: `by_${contactType}`
  });

export const trackComplaintPolicyDBD = (contactNumber: string) =>
  sendToDataLayer(undefined, {
    event_category: COMPLAINT_POLICY_EVENT_CATEGORY,
    event_action: 'contact_dbd',
    event_label: `by_${contactNumber}`
  });
