import { FC } from 'react';
import {
  trackComplaintPolicyGoTo,
  trackComplaintPolicy,
  trackComplaintPolicyDBD,
  ComplainContactType
} from './tracking';
import { DefaultProps } from '../interface';

const contact = {
  line: '@drivehub',
  tel: '02-038-5222',
  email: 'support@drivehub.co',
  websiteDBD: 'www.dbd.go.th'
};

const contactTitle = {
  th: {
    tel: 'โทร :',
    fax: 'โทรสาร :',
    email: 'อีเมล :',
    website: 'เว็บไซต์ :'
  },
  en: {
    tel: 'Tel:',
    fax: 'Fax:',
    email: 'Email:',
    website: 'Website:'
  }
};

const content = {
  th: [
    'มาตรการคุ้มครองและรักษาความลับของผู้ร้องเรียน',
    <>
      เพื่อเป็นการคุ้มครองสิทธิของผู้ร้องเรียนและผู้ให้ข้อมูลที่กระทำโดยเจตนาสุจริตทาง{' '}
      <a rel="noreferrer" href="http://www.drivehub.com/" onClick={trackComplaintPolicyGoTo}>
        Drivehub
      </a>{' '}
      จะปกปิดชื่อ ที่อยู่ หรือข้อมูลใด ๆ ที่สามารถระบุตัวผู้ร้องเรียนหรือผู้ให้ข้อมูลได้
      และเก็บรักษาข้อมูลของผู้ร้องเรียนและผู้ให้ข้อมูลไว้เป็นความลับ
      โดยจำกัดเฉพาะผู้รับผิดชอบในการดำเนินการตรวจสอบเรื่องร้องเรียนเท่านั้นที่จะเข้าถึงข้อมูลดังกล่าวได้ ทั้งนี้
      ผู้ได้รับข้อมูลจากการปฏิบัติหน้าที่ที่เกี่ยวข้องกับเรื่องร้องเรียน มีหน้าที่เก็บรักษาข้อมูล ข้อร้องเรียน
      และเอกสารหลักฐานของผู้ร้องเรียนและผู้ให้ข้อมูลไว้เป็นความลับ
      ห้ามเปิดเผยข้อมูลแก่บุคคลอื่นที่ไม่มีหน้าที่เกี่ยวข้อง เว้นแต่เป็นการเปิดเผยตามหน้าที่ที่กฎหมายกำหนด
    </>,
    'การดำเนินการเมื่อได้รับเรื่องร้องเรียน',
    <>
      <a rel="noreferrer" href="http://www.drivehub.com/" onClick={trackComplaintPolicyGoTo}>
        Drivehub
      </a>{' '}
      จะเป็นผู้พิจารณาเรื่องร้องเรียนที่ได้รับทันที
      และแจ้งให้หน่วยงานที่เกี่ยวข้องดำเนินการสอบสวนข้อเท็จจริงเกี่ยวกับเรื่องที่ได้รับ
      การร้องเรียนและดำเนินการแก้ไขอย่างเหมาะสม{' '}
      <a rel="noreferrer" href="http://www.drivehub.com/" onClick={trackComplaintPolicyGoTo}>
        Drivehub
      </a>{' '}
      จะติดตามผลความคืบหน้าเป็นระยะ
      ซึ่งหน่วยงานที่เกี่ยวข้องจะแจ้งผลการดำเนินงานภายในระยะเวลาที่เหมาะสมให้แก่ผู้ร้องเรียนทราบ โดยไม่มีค่าใช้จ่าย
    </>,
    'ช่องทางการรับเรื่องร้องเรียน',
    'ผู้ร้องเรียนจะต้องระบุรายละเอียดของเรื่องที่จะร้องเรียน พร้อมชื่อ ที่อยู่ และหมายเลขโทรศัพท์ที่สามารถติดต่อได้ และส่งมาได้ 2 ช่องทาง คือ',
    'ช่องทางที่ 1 ทางจดหมายอิเล็คทรอนิกส์ ไปยังที่อยู่อีเมล :',
    'ช่องทางที่ 2 ผ่านทาง LINE@ Official Account โดยสามารถเพิ่มเพื่อนได้จากไอดีไลน์ :',
    'หรือผู้ร้องเรียนสามารถติดต่อแจ้งเรื่องร้องเรียนผ่านทางโทรศัพท์ได้โดยตรงกับเจ้าหน้าที่ โดยติดต่อที่เบอร์',
    '(ช่วงเวลา 8:00-22:00)',
    'ช่องทางการระงับข้อพิพาทโดยกลไกภายนอก',
    'หากกระบวนการแก้ไขข้อพิพาทโดยผู้ประกอบการไม่เป็นที่พอใจของลูกค้า ลูกค้าสามารถใช้ช่องทางภายนอกที่ลูกค้าสามารถดำเนินการระงับข้อพิพาทได้ โดยติดต่อไปยัง',
    'กรมพัฒนาธุรกิจการค้า',
    '563 ถนนนนทบุรี ตำบลบางกระสอ อำเภอเมือง จังหวัดนนทบุรี 11000',
    '(กองพาณิชย์อิเล็กทรอนิกส์ e-Commerce)'
  ],
  en: [
    'Measures to Protect and Maintain the Confidentiality of Complainants',
    'In a commitment to ensuring the rights of complainants and individuals who provide information in good faith, Drivehub maintains a rigorous policy to shield the identities of complainants and informants. This encompasses names, addresses, and any particulars that may lead to the identification of said parties. The data relating to complainants and informants is treated with the utmost discretion, and access to this information is strictly restricted to authorized personnel tasked with conducting complaint investigations. Additionally, individuals entrusted with information as part of their responsibilities pertinent to the complaint are bound to uphold the confidentiality of both the complaint and its accompanying documentation. Unauthorized disclosure of such information is expressly prohibited, save for instances dictated by legal obligations.',
    'Operational Protocol Upon Receipt of Complaints',
    `Drivehub promptly undertakes a comprehensive review of all received complaints, promptly initiating relevant investigative procedures to address the issues at hand. Ensuring the appropriate measures are enacted to rectify the matter, Drivehub oversees the progression of each complaint resolution and subsequently informs the involved parties of the investigation's findings within a reasonable timeframe. All such reporting is rendered without any associated fees.`,
    'Designated Channels for Submitting Complaints',
    'Complainants are required to furnish precise and thorough details of the matter they are seeking to bring to attention, accompanied by their full name, address, and a valid contact number. Two avenues are provided for the submission of complaints:',
    'Channel 1: Submission via electronic mail to the address: support@drivehub.co',
    'Channel 2: Utilization of the LINE@ Official Account, facilitated through the addition of the LINE ID:',
    `Alternatively, complainants may directly engage Drivehub's customer service by dialing`,
    '(operational hours: 8:00 AM - 10:00 PM).',
    'External Channels for the Resolution of Disputes',
    'In instances where a customer expresses dissatisfaction with the internal dispute resolution mechanisms offered by Drivehub, an external avenue is accessible. Complainants are entitled to approach the Department of Business Development using the following contact information:',
    'Department of Business Development',
    '563 Nonthaburi Road, Bang Kra Sor Sub-district, Muang District, Nonthaburi Province, 11000',
    '(e-Commerce Division)'
  ]
};
const ComplaintPolicy: FC<DefaultProps> = ({ prefix }) => {
  const text = content[prefix];
  const titleOfContact = contactTitle[prefix];

  return (
    <div className="complain-policy">
      <div className="mb-2">
        <p className="mb-2">
          <strong>{text[0]}</strong>
        </p>
        {text[1]}
      </div>
      <div className="mb-2">
        <p className="mb-2">
          <strong>{text[2]}</strong>
        </p>
        {text[3]}
      </div>
      <div className="mb-2">
        <p className="mb-2">
          <strong>{text[4]}</strong>
        </p>
        {text[5]}
        <br />
        {text[6]}{' '}
        <a href="mailto:support@drivehub.co" onClick={() => trackComplaintPolicy(ComplainContactType.Email)}>
          {contact.email}
        </a>
        <br />
        {text[7]}{' '}
        <a
          rel="noreferrer"
          href="https://lin.ee/axOqULu"
          onClick={() => trackComplaintPolicy(ComplainContactType.Line)}
        >
          {contact.line}
        </a>
        <br />
        {text[8]}
        <a rel="noreferrer" href="tel:+6620385222" onClick={() => trackComplaintPolicy(ComplainContactType.CallCenter)}>
          {' '}
          {contact.tel}
        </a>{' '}
        {text[9]}
      </div>
      <div className="mb-2">
        <p className="mb-2">
          <strong>{text[10]}</strong>
        </p>
        {text[11]} <br /> <br />
        {text[12]} <br />
        {text[13]} <br />
        {titleOfContact.tel}{' '}
        <a rel="noreferrer" href="tel:+6625475960" onClick={() => trackComplaintPolicyDBD('025475960')}>
          0-2547-5960
        </a>{' '}
        {titleOfContact.fax}{' '}
        <a rel="noreferrer" href="tel:+6625475973" onClick={() => trackComplaintPolicyDBD('025475973')}>
          0-2547-5973
        </a>{' '}
        {text[14]} <br />
        {titleOfContact.email}{' '}
        <a href="mailto:e-commerce@dbd.go.th" onClick={() => trackComplaintPolicy(ComplainContactType.Email)}>
          {contact.email}
        </a>{' '}
        {titleOfContact.website}{' '}
        <a href="http://www.dbd.go.th/" rel="noreferrer" onClick={() => trackComplaintPolicy(ComplainContactType.DBD)}>
          {contact.websiteDBD}
        </a>{' '}
        <br />
      </div>
    </div>
  );
};

export default ComplaintPolicy;
